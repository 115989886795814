import React, { Component } from 'react';
import './App.css';

class App extends Component {
    render() {
        return (
            <div className="wrapper">
                <main className="container">
                    <code>Currently under construction...</code>
                </main>
            </div>
        );
    }
}

export default App;
